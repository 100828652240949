export class LanguageKeys {
  public dates = {
    Mi: '26.06.',
    Do: '27.06.',
    Fr: '28.06.',
    Sa: '29.06.',
    So: '30.06.',
    Mo: '01.06.',
  }

  public WELCOME_HEADLINE = 'Willkommen auf der Fusion 2024'
  public WELCOME_MESSAGE =
    'Dies ist der mobile Timetable für Dein Smartphone. Stöbere im diesjährigen Programm herum stelle die deinen eigenen Merkzettel zusammen.'
  public WELCOME_MESSAGE_IOS =
    'Dies ist der mobile Timetable für Dein Smartphone. Stöbere im diesjährigen Programm herum stelle die deinen eigenen Merkzettel zusammen. '
  public WELCOME_MESSAGE_NOT_RELEASED =
    'Das Programm ist noch nicht veröffentlicht. Komme später wieder.'

  public INSTALL_PROMT_HEADLINE = 'Offline verfügbar'
  public INSTALL_PROMT_MESSAGE =
    'Dieser Timetable funktioniert auch offline. Installiere ihn auf deinem Handy und nutze ihn wie eine App.'
  public INSTALL_MANUALLY_HEADLINE = 'Offline Nutzung'
  public INSTALL_MANUALLY_MESSAGE =
    'Dieser Timetable funktioniert auch offline und das geht so:<br /><br />- in Chrome öffnen<br />- Browsermenü öffnen<br />- <b>»App installieren«</b> klicken<br /><br /><br />Einmal geklickt, wird ein Icon auf Deinem Homescreen erscheinen, hinter dem sich der Timetable befindet. Anschliessend die App einmal starten, wodurch sie offline nutzbar wird.'
  public INSTALL_MANUALLY_MESSAGE_IOS =
    'Dieser Timetable funktioniert auch offline und das geht so:<br /><br />Seite im Safari öffnen, Menu clicken, »Zum Homescreen hinzufügen«.<br /><br />Einmal geklickt, wird ein Icon auf Deinem Homescreen erscheinen, hinter dem sich der Timetable befindet. Anschliessend die App einmal starten, wodurch sie offline nutzbar wird.'

  public WELCOME_BUTTON_START = 'Start'
  public NEXT = 'Weiter'
  public PASSWORD_HEADLINE = 'Passwort erforderlich!'
  public PASSWORD_MESSAGE =
    'Bitte gebe das Passwort ein welches im Programmheft auf der letzten Seite bzw. im Pocketguide abgedruckt ist. Dadurch schaltest du den Datenteil frei.'
  public PASSWORD_INPUT_PLACEHOLDER = 'Hier Passwort eingeben'

  public TENT_TITLE = 'Wo ist mein Zelt?'
  public TENT_MESSAGE =
    'Schiebe das Icon mit dem Zelt an den Ort auf der Karte auf der du campst und raste es mit dem Schloss Button ein.'
  public TENT_DRAG_ME = 'Verschiebe mich'
  public TENT_LOCK_ME = 'Raste mich ein'

  public EMERGENCY_HEADLINE_1 = 'Unfall?'
  public EMERGENCY_HEADLINE_2 = 'Krankheit?'
  public EMERGENCY_HEADLINE_3 = 'Schlägerei?'
  public EMERGENCY_MESSAGE =
    "Hilfe Kontakt zu Security und medizinischem Notdienst gibt's unter"
  public EMERGENCY_NUMBER = '+49 39833 / 2740400'

  public LOADING = 'lade Programm ...'

  public BUTTON_OK = 'OK'
  public BUTTON_TIMETABLE = 'Programm'
  public BUTTON_FAVORITES = 'Merkzettel'
  public BUTTON_ARTISTS = 'Künstler'
  public BUTTON_MAP = 'Map'
  public BUTTON_DEPARTURE = 'Abreise'
  public BUTTON_FILTER_TIMETABLE = 'Einträge Filtern'
  public BUTTON_FILTER_FAVORITES = 'Merkzettel Filtern'
  public BUTTON_UNDERSTOOD = 'Verstanden'

  public TITLE_PAGE_FILTER = 'Programm filtern'

  public TITLE_PAGE_DEPARTURE = 'Aktuelle infos von ZOB'
  public PAGE_DEPARTURE_NEUSTRELITZ = 'Shuttle Neustrelitz'
  public PAGE_DEPARTURE_BASSLINER = 'Bassliner'

  public DAY = (day: string) => day
  public TYPE = (type: string) => type

  public DAY_WITH_DATE = (day: string) => {
    // let date = this.datesDe[day]
    const date: string = (this.dates as any)[day]
    if (!date) {
      return day
    }
    return `${day} ${date}`
  }

  public OCLOCK = 'Uhr'

  public FILTER_WHEN = 'WANN'
  public FILTER_WHAT = 'Genre'
  public FILTER_FLOOR = 'WO'
  public FILTER_GENRE = 'Genre'
  public FILTER_FLOORS = 'Bühnen'
  // FILTER_SEARCH =  'Suche'
  public FILTER_BUTTON_CHANGE = 'Ändern'
  public FILTER_BUTTON_DELETE = 'Löschen'
  public FILTER_ALL_TYPES = 'Alle Arten'
  public FILTER_ALL_FLOORS = 'Alle Floors'
  public FILTER_CURRENT_SELECTION = 'Künstler Suchen'

  public TIMETABLE_NO_ENTRIES_HEADLNE = 'Keine passenden Einträge gefunden.'
  public TIMETABLE_NO_ENTRIES_MESSAGE =
    'Ändere die Filter um hier mehr zu sehen.'

  public FAVORITES_NO_ENTRIES_HEADLNE = 'Keine Merkzettel Einträge gefunden.'
  public FAVORITES_NO_ENTRIES_MESSAGE_DAY =
    'OOOooops… Es gibt noch keine Enträge für diesen Tag. Schau ins Programm und füge welche hinzu.'
  public FAVORITES_NO_ENTRIES_MESSAGE =
    'OOOooops… Es gibt noch keine Enträge. Schau ins Programm und füge welche hinzu.'

  public IMPORT_EXPORT_BTN = 'Import/Export'
  public INFO_CLOSE_BTN = 'Info schließen'
  public SEARCH_BTN = 'Suche'
  public SEARCH_RESULT = 'Suchergebnis'
  public SEARCH_OK_BTN = 'OK'
  public SEARCH_CLOSE_BTN = 'Schließen'
  public SEARCH_PLACEHOLDER = 'Programm durchsuchen'
  public SEARCH_PLACEHOLDER_ARTIST = 'Programm durchsuchen'

  public FAVORITES_EXPORT_HEADLINE = 'Merkliste Export'
  public FAVORITES_EMAIL_DESC =
    'Klick den Button um eine Email mit deinen Favoriten zu versenden.'
  public FAVORITES_CLIPBOARD_DESC =
    'Oder kopiere den Text in die Zwischenablage und sende ihn an deine Freunde.'
  public FAVORITES_SHARE_DESC =
    'Teile deine Merkliste mit einer App deiner Wahl, wie z.b. Signal oder Telegram.'
  public FAVORITES_EMAIL_BTN = 'Per Email senden'
  public FAVORITES_CLIPBOARD_BTN = 'In Zwischenablage kopieren'
  public FAVORITES_SHARE_BTN = 'Merkliste Teilen'

  public FAVORITES_SHARE_TITLE = 'Fusion Timetable'
  public FAVORITES_SHARE_TEXT = (link: string) =>
    `
Ich möchte meinen Fusion Merkzettel mit dir teilen. Klicke den Link um ihn zu importieren:

${link}

Manueller Import - nur benutzen, wenn der automatische Import nicht funktioniert: 
Öffne die Timetable App und navigiere zu Menu -> Merkzettel -> Import/Export.
Kopiere den Text zwischen START und ENDE in das "Import"-Eingabefeld in der Timetable App.

Viel Spaß beim Fusionieren!
`
  public FAVORITES_SHARE_URL = 'https://timetable.fusion-festival.de'

  public FAVORITES_IMPORT_HEADLINE = 'Merkliste Import'
  public FAVORITES_IMPORT_DESC =
    'Füge den Import-Code aus der Mail bzw. Nachricht hier ein und klicke den Button:'
  public FAVORITES_IMPORT_BTN = 'Importieren'
  public FAVORITES_CLOSE_BTN = 'Schließen'

  public FAVORITES_COPY_CLIPBOARD_SUCCESS =
    'Der Code wurde in der Zwischenablage kopiert. Füge den Code in eine andere App ein und versende ihn an andere.'
  public FAVORITES_IMPORT_SUCCESS = 'Favoriten wurden importiert.'
  public FAVORITES_IMPORT_ERROR =
    'Fehler beim Importieren. Bitte überprüfe die Eingabe.'

  public RELOAD_APP_VERSION_INFO = 'Versions Info'
  public RELOAD_APP_CURRENT_VERSION = 'Aktuelle Version:'
  public RELOAD_APP_LAST_UPDATE = 'Letzte Aktualisierung:'
  public RELOAD_APP_DESC =
    'Zum Aktualisieren der App musst du den Browser Tab bzw. die App einmal schließen und erneut öffnen.'
  public RELOAD_APP_CONFIRMATION = 'Klicke nur dann OK, wenn du Internet hast.'
  public RELOAD_APP_BTN = 'Reload APP'

  public ARTIST_INFO_PLAYTIME = 'Playtimes'

  // Map
  public NEARBY = 'In Deiner Nähe'
  public PLACES = 'Orga'
  public LOCATION_DENIED =
    'Du konntest nicht auf dem Festivalgelände geortet werden. Zur Ortung mußt Du Deinem Browser in den Einstellungen den Zugriff auf deinen Standort gewähren und erneut öffnen.'

  // Departure
  public DEPARTURE_NO_INFO_HEADLINE = 'Keine Infos verfügbar'
  public DEPARTURE_NO_INFO_MESSAGE = 'Zur Zeit gibt es keine Infos vom ZOB.'
  public DEPARTURE_LAST_UPDATE = 'letztes Update vom '
  public DEPARTURE_LOAD_ERROR =
    'Sorry, Kein Internet. Bitte versuche es später nochmal.'
  public DEPARTURE_LOADING = 'lade Daten ...'

  public FONT_SIZE = 'Schriftgröße anpassen'

  public ARTIST_NOTES = 'Platz für Notizen'
  public ARTIST_NOTES_SAVE = 'Speichern'
  public ARTIST_HIGHLIGHT = 'Artist hervorheben'
  public ARTIST_HIGHLIGHT_REMOVE = 'Artist Hervorhebung entfernen'
}

export type LangProps = keyof LanguageKeys

export default LanguageKeys
