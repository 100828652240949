import React from 'react'
import { floorDescriptions } from '../state/filter/consts'
import {
  Page,
  Content,
  BottomDocked,
  Row,
  Button,
  H1,
  Text,
  Spacer,
  T,
  BreakLongWords,
} from '../components'
import { useLang } from '../state/language'
// import { useLang } from '../state/language'

type Props = {
  floor: string
  onClose: () => void
}

export const FloorInfo: React.FC<Props> = ({ floor, onClose }) => {
  const lang = useLang()
  const floorDescription = floorDescriptions[lang][floor]

  // console.log({ shows })

  return (
    <Page withBackground={true} alignLeft>
      <Content>
        <H1>
          <BreakLongWords>{floor}</BreakLongWords>
        </H1>

        <Spacer size={2} />

        <Text>{floorDescription}</Text>
      </Content>
      <BottomDocked>
        <Row>
          <Button onClick={onClose}>
            <T id="INFO_CLOSE_BTN" />
          </Button>
        </Row>
      </BottomDocked>
    </Page>
  )
}
