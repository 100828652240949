import * as R from 'ramda'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  Artist,
  ArtistExtraItem,
  SetNotePayload,
  SetTagPayload,
  TimetableEntry,
} from './types'
import { decodeTimetable } from './decodeTimetable'
import { RootState } from '../store'
import { loadState } from '../../helper/LocalStorage'

export type ArtistExtras = Record<string, ArtistExtraItem>

const savedState: RootState | undefined = loadState()

type TimetableState = {
  favorites: string[]
  password: string | null
  isLoading: boolean
  artistExtras: ArtistExtras
  artists: Artist[]
  timetableItems: TimetableEntry[]
}

const getOriginalState = (): TimetableState => {
  const state: TimetableState = {
    favorites: [],
    password: null,
    isLoading: true,
    artistExtras: {},
    artists: [],
    timetableItems: [],
  }

  if (savedState?.timetable.favorites) {
    state.favorites = savedState?.timetable.favorites
  }
  if (savedState?.timetable.artistExtras) {
    state.artistExtras = savedState.timetable.artistExtras
  }

  return state
}

// export const loadTimeTable = () => {
//   // eslint-disable-next-line no-console
//   console.log('loadTimeTable()')
//   return (dispatch, getState) => {
//     if (timetable.length > 0 || isTTLoading) {
//       // console.log('timetable already loaded.')
//       return
//     }

//     isTTLoading = true

//     dispatch(setLoading(true))

//     import('../../artists.json')
//       .then((m) => m.default)
//       .then((loadedArtists) => {
//         // eslint-disable-next-line no-console
//         console.log('Artists loaded')
//         // @ts-ignore
//         artists = loadedArtists
//         timetable = decodeTimetable(artists)
//         dispatch(setLoading(false))
//       })
//     // })
//   }
// }

const timetableSlice = createSlice({
  name: 'timetable',
  initialState: getOriginalState(),
  reducers: {
    test: () => {},
    // openFilter: (state, action: PayloadAction<string | null>) => {
    //   return {
    //     ...state,
    //     openedFilter: action.payload,
    //   }
    // },
    toggleFav: (state, action: PayloadAction<string>) => {
      const itemId = action.payload
      let favorites = [...state.favorites]

      const index = R.findIndex((val) => val === itemId)(favorites)

      if (index === -1) {
        favorites.push(itemId)
      } else {
        favorites = R.remove(index, 1, favorites)
      }

      return {
        ...state,
        favorites,
      }
    },

    importFavs: (state, action: PayloadAction<string[]>) => {
      let favorites = action.payload
      favorites = R.pipe(R.concat(favorites), R.uniq)(state.favorites)
      return {
        ...state,
        favorites,
      }
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: action.payload,
      }
    },

    setTag: (state, action: PayloadAction<SetTagPayload>) => {
      console.log('SET_TAG: ', action)
      const { artistId, tag } = action.payload
      const { artistExtras } = state

      if (!artistExtras[artistId]) {
        artistExtras[artistId] = {
          note: '',
          tag: '',
          tags: [],
        }
      }

      const artistExtra = artistExtras[artistId]

      if (artistExtra.tag === tag) {
        artistExtra.tag = undefined
      } else {
        artistExtra.tag = tag
      }

      if (artistExtra.tags.includes(tag)) {
        artistExtra.tags = artistExtra.tags.filter((t) => t !== tag)
      } else {
        // artistExtra.tags = [...artistExtra.tags, tag]
        artistExtra.tags = [tag]
      }
    },

    setNote: (state, action: PayloadAction<SetNotePayload>) => {
      console.log('SET_NOTE: ', action)
      const { artistId, note } = action.payload

      if (!state.artistExtras[artistId]) {
        state.artistExtras[artistId] = {
          note: '',
          tag: '',
          tags: [],
        }
      }

      const artistExtra = state.artistExtras[artistId]
      artistExtra.note = note
    },

    setArtists: (state, action: PayloadAction<Artist[]>) => {
      const artists = action.payload
      state.artists = artists
      state.isLoading = false

      const timetable = decodeTimetable(artists)
      state.timetableItems = timetable
    },
  },
})

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const timetableActions = timetableSlice.actions

export const {
  importFavs,
  setArtists,
  setLoading,
  setNote,
  setTag,
  toggleFav,
} = timetableActions

// Export the slice reducer as the default export
export default timetableSlice.reducer

const getOwnState = (state: RootState) => state.timetable
export const getFavorites = (state: RootState) => getOwnState(state).favorites
export const getArtistExtras = (state: RootState) =>
  getOwnState(state).artistExtras
export const getPassword = (state: RootState) => getOwnState(state).password
export const isLoading = (state: RootState) => getOwnState(state).isLoading

export const getTimetable = (state: RootState) =>
  getOwnState(state).timetableItems
export const getArists = (state: RootState) => getOwnState(state).artists
export const getArtistById = (state: RootState, id: string) => {
  // console.log('id: ', {
  //   id,
  //   artists,
  // })
  const artist = R.find(R.propEq('id', parseInt(id, 10)))(getArists(state))
  return artist as Artist | undefined
}
