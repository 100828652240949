const palette = {
  primary: '#B84740',
  primaryDark: '#594C5C',
  primaryLight: '#F1E3F3',
  // primaryMedium: '#39183D',
  secondary: '#B84740',
  secondaryLight: '#FBEEE4',
  third: '#848283',
  fourth: '#5C2734',
  black: '#000000',
  white: '#FFFFFF',

  grey: {
    100: '#F4F2F5',
    200: '#E8E4EA',
    300: '#D6CED9',
    400: '#BDB0C2',
    1000: '#2D242E',
  },

  blue: {
    400: '#799FB7',
  },

  textGrey: '#2D242E',
}

const colors = {
  whiteDark: palette.secondaryLight,
  white: palette.white,

  backgroundDark: '#2b2b2b',
  backgroundBlack: palette.black,
  backgroundTransparent: '#252424cc',
  backgroundTransparent2: '#252424ee',

  text: palette.textGrey,
  textInverted: '#FFFFFF',

  primary: palette.primary,
  primaryDark: palette.primaryDark,
  secondary: palette.secondary,

  filtersBackground: palette.primaryDark,

  highlight: palette.fourth,
  favorite: '#645556',
  favoriteLight: '#433839',

  plusButton: palette.secondary,
  plusButtonFavorite: palette.secondaryLight,

  scrollUpButton: '#c74544',
  scrollUpButtonActive: '#672222',

  timeHeader: {
    background: '#4C414E',
    // background: palette.primaryDark,
    border: palette.primaryLight,
  },

  SpecialContent: {
    bg: palette.grey[1000],
    divider: palette.primaryDark,
  },

  DayButtons: {
    activeBg: palette.white,
    activeText: palette.textGrey,
  },

  Entry: {
    background: palette.black,
    // backgroundFavorite: palette.primaryDark,
    backgroundFavorite: '#2D242E',
    border: palette.primary,
    floorItem: palette.primaryDark,
    favorite: {
      bg: palette.grey['100'],
      text: palette.black,
    },
    tag: palette.blue[400],
  },

  Search: {
    textColor: palette.primaryDark,
    background: palette.grey[100],
    closeIconBg: palette.primary,
    closeIconText: palette.white,
  },

  Filters: {
    titleBackground: palette.primaryDark,
    border: palette.primaryLight,
  },

  zIndex: {
    timeTable: 10,
    stickyStuff: 11,
    menu: 20,
  },

  error: {
    textColor: palette.secondary,
  },
}

const space = [0, 4, 8, 12, 16, 24, 32]

export default colors

export { colors, space }
